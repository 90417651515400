.App {
  width: 100vw;
  height: 100vh;
  font-family: Courier, Courier New;
  font-size: 18px;
  font-weight: 100;
}
  .bigColor {
    width: 100%;
    height: 100%;
  }
  .info {
    position: fixed;
    width: 310px;
    top: 10px;
    left: 50%;
    transform: translate(-50%);
    border-radius: 14px;
    box-shadow: 1px 1px 10px;
    background-color: #fff;
    text-align: center;
    padding: 8px;
    transition: opacity 0.4s, transform 0.4s;
    opacity: 1;
  }
    .info.hidden {
      opacity: 0;
      transform: translate(-50%) scale(0.8);
      transition: all 0s;
    }
    .info * {
      user-select: text;
    }
  .dirs {
    color: #888;
    font-size: 15px;
    margin-top: 8px;
    user-select: none;
    font-family: Arial;
  }
  .logoLink {
    position: fixed;
    width: 160px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }
  .logoLink img {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 20px;
  }
